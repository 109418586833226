import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import video1 from '../../assets/videos/12.mp4'
import video2 from '../../assets/videos/18.mp4'
import video3 from '../../assets/videos/21.mp4'
import video4 from '../../assets/videos/32.mp4'
import video5 from '../../assets/videos/33.mp4'
import video6 from '../../assets/videos/34.mp4'
import video7 from '../../assets/videos/35.mp4'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignHP2 = props => (
  <Layout>
    <Helmet>
      <title>Hive digital touchpoint design</title>
      <meta
        name="Digital touchpoint design of a food experince service"
        content="Service Design"
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.hp2cover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <span className="tag">INTERACTION DESIGN</span>
            <span className="tag">DIGITAL UX</span>
            <span className="tag">DIGITAL UI</span>
            <h1>hive (part 2) - design of digital touchpoints.</h1>
            <h3>
              Eating healthy is possible when it is fun and easily accessible.
              The Hive Mobile Application allows users to find & book the food
              experiences they like. On the other end, the Host Dashboard gives
              local chefs all the tools they need to host the perfect food
              experience.
            </h3>
          </header>
          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>the challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                  To design the digital experiential touchpoints of the service
                  Hive. It's a service that offers unique experiences around
                  food by bringing people together over home cooked meals hosted
                  by local chefs in neighbourhoods.
                </p>
                <p>
                  The Hive service was designed in 2 parts. Part 1 involved the
                  strategic & service design as seen in the picture below.
                  <a href="/work/service-design-hive-part-1">
                    <u>Hive part 1 project link</u>
                  </a>
                </p>
              </div>
              <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2018
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 3 months
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Interaction design, UX/UI
                  design of mobile app, desktop website & dashboard.
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Individual project
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Identifying digital touchpoints
                  required to interact with the service, design of information
                  architecture, wireframes, user flows, backend systems and
                  visual elements of the touchpoints, prototyping on Adobe XD.
                </span>
              </div>
            </div>
            <div id="project-images-wrapper">
              <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.hp2challenge1.childImageSharp.fluid} />
                <Img fluid={props.data.hp2challenge2.childImageSharp.fluid} />
                <Img fluid={props.data.hp2challenge3.childImageSharp.fluid} />
                <Img fluid={props.data.hp2challenge4.childImageSharp.fluid} />
              </Slider>
            </div>
            <p className="full-text">
              Part 2 of the project involved designing the digital touchpoints
              which allowed users to get aware of the Hive service, explore
              experiences & make bookings. The backend of the service was also
              to be designed in order to facilitate the local chefs to make
              listings on the platform, manage their listings, track data
              regarding the performance of their listed experience and finally
              to be able to make ingredient purchases on the Hive Market.
            </p>

            <div className="project-outcome">
              <a href={"#outcome-section"} className="project-outcome-link">
              see project outcomes >>
              </a>
            </div>
            
          </section>
          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>the approach</h2>
            <p className="full-text">
              The mapped end-to-end experience of the service (part 1) was used
              to identify the touchpoints that were required by users and hosts
              at different stages of their respective journeys. 3 digital
              touchpoints were identified; a mobile application, website and a
              host dashboard.
            </p>
            <div className="process-image">
              <Img fluid={props.data.hive2_process.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              Next, the main Jobs To Be Done (JTBD) and its respective flows the
              user & local chef (2 personas) took using the digital touchpoint
              were mapped out.
            </p>
            <div className="process">
              <div className="process-left">
                <div>
                  The main JTBD (flows) for the user are:
                  <ol>
                    <li>Onboarding on Hive using the mobile app</li>
                    <li>Exploring different experiences</li>
                    <li>Booking an experience</li>
                    <li>Tracking of upcoming experience</li>
                    <li>Attending the experience</li>
                    <li>Posting a rating of the experience</li>
                  </ol>
                </div>
              </div>
              <div className="process-right">
                <div>
                  The main JTBD (flows) for the local chef are:
                  <ol>
                    <li>Onboarding to join the hive platform as a host</li>
                    <li>Listing a new experience on the platform</li>
                    <li>Managing their portfolio of experiences</li>
                    <li>Checking data analytics of their experience</li>
                    <li>Buying fresh-local ingredients on Hive Market</li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="image">
              <Img fluid={props.data.hp2process5.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              The framework shown above was used to identify the required
              features, pages, & interaction modalities to meet the needs of the
              user & local chef in each step to complete their respective jobs
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.hp2process6.childImageSharp.fluid} />
              <Img fluid={props.data.hp2process7.childImageSharp.fluid} />
              <Img fluid={props.data.hp2process8.childImageSharp.fluid} />
              <Img fluid={props.data.hp2process9.childImageSharp.fluid} />
            </Slider>
            <p className="full-text">
              The pages that were identified above for the mobile app, host
              dashboard and the website were used to map out the site map &
              information architecture for the Hive website and mobile app.
            </p>
            <div className="image">
              <Img fluid={props.data.hp2process10.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              This was followed by making wireframes of the different pages.
              While designing various things were taken into consideration such
              as features, actions, flows to/from other screens, information
              hierarchy, architecture and interactions modalities. The design
              process of key functionalities of the touchpoint is outlined
              below.
            </p>
            <h4>ONBOARDING </h4>
            <div className="full-text">
              <p>
                A quick 3 step process for the user to set their preferences
                regarding the type of experience, ambience style and food they
                like. This allows the service to recommend personalised listings
                according to the users preferences.
              </p>
            </div>
            <div className="image">
              <Img fluid={props.data.hp2process11.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              At the end of the onboarding process, users see a message to
              informs the user how this data that they have shared will be used
              by the service. Being transparent helps in gaining the user trust
              and loyalty for the service.
            </p>
            <h4>APP HOMEPAGE</h4>
            <div className="full-text">
              <p>
                The bottom navigation bar divides the app into 5 main section.
                On the homepage users can explore all the experiences listed on
                the platform. The "for you" tab allow the user to check
                experience that are suggested & tailored for them based on the
                preferences they have set and their app usage data.
              </p>
            </div>
            <div className="video">
              <video controls>
                <source src={video1} type="video/mp4" />
              </video>
            </div>
            <div className="image">
              <Img fluid={props.data.hp2process13.childImageSharp.fluid} />
            </div>
            <div className="full-text">
              The listings have different indicators that give quick & useful
              information about the experience:
              <ol>
                <li>
                  Automatic acceptance of the booking request are indicated by
                  the lighting icon next to price
                </li>
                <li>
                  "Demo event" label are shown for listings that are new on the
                  Hive platform and in test phase.
                </li>
                <li>
                  Listings that are hosted by top chefs are shown by the yellow
                  icon
                </li>
                <li>
                  Listing that are categorised as an "all-inclusive experience"
                  are shown by an blue icon
                </li>
              </ol>
            </div>
            <div className="image">
              <Img fluid={props.data.hp2process14.childImageSharp.fluid} />
            </div>
            <div className="process">
              <h4>APPLYING PHYSCOLOGY PRINCIPLES IN DESIGN</h4>
              <div className="process-left">
                <div>
                  <p>
                    Phycology principles are used as triggers to optimise user
                    flows and impact business goals. It reframes information and
                    alerts users when there is a need for urgency; while at the
                    same time being ethical and giving control to the users to
                    make the final decision. These statuses change in real-time
                    and help in enhancing customer experience.
                  </p>
                </div>
              </div>
              <div className="process-right">
                <Img fluid={props.data.hp2process15.childImageSharp.fluid} />
              </div>
            </div>
            <h4>EASILY FIND THE PERFECT EXPERIENCE</h4>
            <div className="full-text">
              <p>
                On the homepage, the search bar and smart filter tabs makes it
                easy to find the perfect food experience for every type of meal
                the user is looking for. The taxonomy shown below makes it
                easier to filter the perfect experience. Big database of word
                tags, data from image recognition, comments and ratings makes
                this possible.
              </p>
            </div>
            <div className="image">
              <Img fluid={props.data.hp2process16.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              The large database of experiences on the platform are divided into
              various "experience type" categories. These categories are
              represented as tiles that allow for easy discovery, accessibility
              and quick exploration.
            </p>
            <div className="image">
              <Img fluid={props.data.hp2process17.childImageSharp.fluid} />
            </div>
            <div className="video">
              <video controls>
                <source src={video2} type="video/mp4" />
              </video>
            </div>
            <div className="full-text">
              Other filters are easily accessible from the different filter tabs
              on the homepage:
              <ol>
                <li>Location filter</li>
                <li>Meal types</li>
                <li>Dates</li>
                <li>More filters +</li>
              </ol>
            </div>
            <p className="full-text">
              The number inside the "explore experiences' button indicates
              number of experiences that are available for the chosen filters.
              This number changes in real-time as different filters are applied.
            </p>
            <div className="image">
              <Img fluid={props.data.hp2process19.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              As filters are applied to the search results, the top section
              shows the user a summary of all the filters they have selected
              with the applied results shown below.
            </p>
            <div className="image">
              <Img fluid={props.data.hp2process20.childImageSharp.fluid} />
            </div>
            <div className="process">
              <h4>BOOKING AN EXPERIENCE</h4>
              <div className="process-left">
                <p>
                  In the experience details page, users first sees images/videos
                  of the experience in full screen with key details such as
                  name, price & rating. As they scroll down, important summary
                  of details is shown, followed by full information about the
                  experience such as meal, ambience, style, location details,
                  pictures of experience, food, space & ambience.
                </p>
              </div>
              <div className="process-right">
                <p>
                  Users can also check details about the host, by accessing
                  their profile. They can message them, see past and upcoming
                  experiences they will hosted. Check their level, points &
                  ratings, and have the ability to report hosts that are not
                  good. By giving users these information and control, it helps
                  in building trust between the user and host before they attend
                  an experience.
                </p>
              </div>
            </div>
            <div className="video">
              <video controls>
                <source src={video3} type="video/mp4" />
              </video>
            </div>
            <p className="full-text">
              The booking wizard allows users to book an experience with ease.
              The progress bar on top shows the number of steps that are
              remaining and at the same time, users can see a quick summary of
              the options they had selected in the previous steps; allowing for
              easy review and quick edits.
            </p>
            <div className="image">
              <Img fluid={props.data.hp2process22.childImageSharp.fluid} />
            </div>
            <h4>TRACKING UPCOMING BOOKED EXPERIENCE </h4>
            <p className="full-text">
              In the homepage users can see banner that reminds them of their
              upcoming experience and its status. In the "your experiences" page
              users can see a timeline of all the past experience they have
              attended and upcoming experiences they have booked.
            </p>
            <div className="image">
              <Img fluid={props.data.hp2process23.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              In the experience detail page users can engage in group discussion
              with the host and the other guests that will be joining the
              experience. This helps in breaking barriers, awkwardness, gaining
              trust for the host and the people who will be coming.
            </p>
            <h4>ATTENDING AN EXPERIENCE</h4>
            <p className="full-text">
              While attending the experience, the user can take pictures, videos
              & live stream the experience with just one click of the camera
              button on the app homepage.
            </p>
            <p className="full-text">
              User can also see a gallery of images, videos of experiences that
              have recently taken place or live streams of events near them or
              around the world, This media is uploaded by other Hive users using
              the various #HIVE #HASHTAGS. While browsing these images users can
              take advantage of the image recognition capability of the service
              to search for similar experiences to those that are seen in the
              image.
            </p>
            <div className="image">
              <Img fluid={props.data.hp2process24.childImageSharp.fluid} />
            </div>
            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.hp2process25.childImageSharp.fluid} />
              <Img fluid={props.data.hp2process26.childImageSharp.fluid} />
              <Img fluid={props.data.hp2process27.childImageSharp.fluid} />
              <Img fluid={props.data.hp2process28.childImageSharp.fluid} />
              <Img fluid={props.data.hp2process29.childImageSharp.fluid} />
              <Img fluid={props.data.hp2process30.childImageSharp.fluid} />
              <Img fluid={props.data.hp2process31.childImageSharp.fluid} />
            </Slider>
            <h4>BACK END DESIGN OF THE HOST DASHBOARD</h4>
            <p className="full-text">
              The back-end of the Hive service was designed to show how the
              local chefs are able to manage their profiles and experiences they
              host on the Hive platform. In the Host Dashboard section they have
              access all the tools and resources to mange their hosted
              experience.
            </p>
            <p className="full-text">
              The first video below shows how a new host accesses the host
              dashboard and the steps they have to take to list their first
              experience. The second video shows how hosts can manage their
              listed experiences by checking the calendar for upcoming
              experience they would be hosting and live status related to its
              bookings.
            </p>

            <div className="video">
              <video controls>
                <source src={video4} type="video/mp4" />
              </video>
            </div>
            <div className="video">
              <video controls>
                <source src={video5} type="video/mp4" />
              </video>
            </div>
            <p className="full-text">
              The video below shows how hosts are able to track how well they
              are hosting by checking Data & Analytics, Hive not only shows
              visual data, but provides them with insights related to their
              performance and actionable suggestions on how they can improve
              their hosted experience. Lastly, the second video below shows how
              the chefs can access the Hive Market section to search for
              upcoming local markets and make online pre-orders for fresh &
              local ingredients.
            </p>
            <div className="video">
              <video controls>
                <source src={video6} type="video/mp4" />
              </video>
            </div>
            <div className="video">
              <video controls>
                <source src={video7} type="video/mp4" />
              </video>
            </div>
          </section>

          <section id="outcome-section" className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>the outcome</h2>
              <h3>
                The design of the digital touchpoint ecosystem consisted of a
                website to raise awareness, a mobile application to explore &
                book experiences and a dashboard for managing experience
                listings.
              </h3>
              <p className="full-text">
                The user flow, information hierarchy & interaction modalities of
                these touchpoints were designed by taking in to consideration
                the needs of the users and the local chef in their respective
                usage context so that they can use these touchpoints to carry
                out tasks in an effective and efficient way. A bold and vibrant
                visual language was chosen to reflect the fun, exciting &
                dynamic nature of the food experiences on the Hive platform.
              </p>
              <h3>
                Finally these online-digital touchpoints were designed to
                integrated seamlessly with the offline-physical experience of
                the Hive service.
              </h3>
            </div>
            <div className="image">
              <Img fluid={props.data.hp2outcome37.childImageSharp.fluid} />
            </div>
            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.hp2outcome38.childImageSharp.fluid} />
              <Img fluid={props.data.hp2outcome39.childImageSharp.fluid} />
            </Slider>
          </section>
        </div>
      </div>
      <section className="fullspan">
        <div className="image">
          <Img fluid={props.data.hp2outcome36.childImageSharp.fluid} />
        </div>
        <p className="center-text black">
          To see the design of the Hive service experience, check out the
          project
        </p>
        <h2 className="next-title">
          <a href="/work/service-design-hive-part-1">
            Hive (part 1) - UX strategy & Service design
          </a>
        </h2>
      </section>
      <section id="footertopcta" className="inner">
        <p className="text arrow-back">
          head back to
          <br />
          <Link to="/work">all my work.</Link>
        </p>
      </section>
    </div>
    <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/service-design-save">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">save.</div>
                <div className="text">
                  An IoT service for smart payment & expense tracking that
                  offers personalised & contextual financial experience.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>UX STRATEGY</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-tecne">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">tecne.</div>
                <div className="text">
                  Next gen combat training with an underglove fitted with
                  sensors & an app that gives precise feedback to improve.
                </div>
                <div className="tags">
                  <ul>
                    <li>UX STRATEGY</li>
                    <li>INTERACTION DESIGN</li>
                    <li>DIGITAL UX/UI</li>
                    <li>PRODUCT DESIGN</li>
                    <li>BRANDING</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ServiceDesignHP2

export const fluidHP2Image = graphql`
  fragment fluidHP2Image on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    hp2cover: file(
      relativePath: { eq: "projects/service/hive2/cover/hive2-cover-image.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2challenge1: file(
      relativePath: { eq: "projects/service/hive2/challenge/1.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2challenge2: file(
      relativePath: { eq: "projects/service/hive2/challenge/2.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2challenge3: file(
      relativePath: { eq: "projects/service/hive2/challenge/3.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2challenge4: file(
      relativePath: { eq: "projects/service/hive2/challenge/4.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2process5: file(
      relativePath: { eq: "projects/service/hive2/process/5.png" }
    ) {
      ...fluidHP2Image
    }
    hp2process6: file(
      relativePath: { eq: "projects/service/hive2/process/6.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2process7: file(
      relativePath: { eq: "projects/service/hive2/process/7.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2process8: file(
      relativePath: { eq: "projects/service/hive2/process/8.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2process9: file(
      relativePath: { eq: "projects/service/hive2/process/9.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2process10: file(
      relativePath: { eq: "projects/service/hive2/process/10.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2process11: file(
      relativePath: { eq: "projects/service/hive2/process/11.png" }
    ) {
      ...fluidHP2Image
    }
    hp2process12: file(
      relativePath: { eq: "projects/service/hive2/process/12.mp4" }
    ) {
      ...fluidHP2Image
    }
    hp2process13: file(
      relativePath: { eq: "projects/service/hive2/process/13.png" }
    ) {
      ...fluidHP2Image
    }
    hp2process14: file(
      relativePath: { eq: "projects/service/hive2/process/14.png" }
    ) {
      ...fluidHP2Image
    }
    hp2process15: file(
      relativePath: { eq: "projects/service/hive2/process/15.png" }
    ) {
      ...fluidHP2Image
    }
    hp2process16: file(
      relativePath: { eq: "projects/service/hive2/process/16.png" }
    ) {
      ...fluidHP2Image
    }
    hp2process17: file(
      relativePath: { eq: "projects/service/hive2/process/17.png" }
    ) {
      ...fluidHP2Image
    }
    hp2process18: file(
      relativePath: { eq: "projects/service/hive2/process/18.mp4" }
    ) {
      ...fluidHP2Image
    }
    hp2process19: file(
      relativePath: { eq: "projects/service/hive2/process/19.png" }
    ) {
      ...fluidHP2Image
    }
    hp2process20: file(
      relativePath: { eq: "projects/service/hive2/process/20.png" }
    ) {
      ...fluidHP2Image
    }
    hp2process21: file(
      relativePath: { eq: "projects/service/hive2/process/21.mp4" }
    ) {
      ...fluidHP2Image
    }
    hp2process22: file(
      relativePath: { eq: "projects/service/hive2/process/22.png" }
    ) {
      ...fluidHP2Image
    }
    hp2process23: file(
      relativePath: { eq: "projects/service/hive2/process/23.png" }
    ) {
      ...fluidHP2Image
    }
    hp2process24: file(
      relativePath: { eq: "projects/service/hive2/process/24.png" }
    ) {
      ...fluidHP2Image
    }
    hp2process25: file(
      relativePath: { eq: "projects/service/hive2/process/25.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2process26: file(
      relativePath: { eq: "projects/service/hive2/process/26.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2process27: file(
      relativePath: { eq: "projects/service/hive2/process/27.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2process28: file(
      relativePath: { eq: "projects/service/hive2/process/28.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2process29: file(
      relativePath: { eq: "projects/service/hive2/process/29.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2process30: file(
      relativePath: { eq: "projects/service/hive2/process/30.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2process31: file(
      relativePath: { eq: "projects/service/hive2/process/31.jpg" }
    ) {
      ...fluidHP2Image
    }
    hp2process32: file(
      relativePath: { eq: "projects/service/hive2/process/32.mp4" }
    ) {
      ...fluidHP2Image
    }
    hp2process33: file(
      relativePath: { eq: "projects/service/hive2/process/33.mp4" }
    ) {
      ...fluidHP2Image
    }
    hp2process34: file(
      relativePath: { eq: "projects/service/hive2/process/34.mp4" }
    ) {
      ...fluidHP2Image
    }
    hp2process35: file(
      relativePath: { eq: "projects/service/hive2/process/35.mp4" }
    ) {
      ...fluidHP2Image
    }
    hp2outcome36: file(
      relativePath: { eq: "projects/service/hive2/outcome/36.png" }
    ) {
      ...fluidHP2Image
    }
    hp2outcome37: file(
      relativePath: { eq: "projects/service/hive2/outcome/37.png" }
    ) {
      ...fluidHP2Image
    }
    hp2outcome38: file(
      relativePath: { eq: "projects/service/hive2/outcome/38.png" }
    ) {
      ...fluidHP2Image
    }
    hp2outcome39: file(
      relativePath: { eq: "projects/service/hive2/outcome/39.png" }
    ) {
      ...fluidHP2Image
    }
    hive2_process: file(
      relativePath: { eq: "projects/process/hive2-process.png" }
    ) {
      ...fluidHP2Image
    }
    project1: file(relativePath: { eq: "projects/5.png" }) {
      ...fluidHP2Image
    }
    project2: file(relativePath: { eq: "projects/15.png" }) {
      ...fluidHP2Image
    }
  }
`
